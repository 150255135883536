import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from 'src/app/core/services/main.service';
import { formObject } from 'src/app/pages/helper/formObject';
import { ActivatedRoute, Router } from '@angular/router';

import { ServiceService } from '../service.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  objectForm = new formObject;
  id = null;
  object = null;
  government_id = null;
  constructor(
    private router: Router,
    private mainService: MainService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private service: ServiceService,

  ) {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.government_id = params.government_id;

      this.mainService.setTitle("Government City " + (this.id ? "Edit" : "Create"));
      this.mainService.changeBreadCrumbItems([
        { label: 'Governments', path: '/users/governments', active: false },
        { label: 'Cities', path: '/users/governments/' + this.government_id + '/cities', active: false },
        { label: (this.id ? "Edit" : "Create"), path: "", active: true },
      ]);
      if (this.id)
        this.getObject();
    });
  }

  getObject() {
    this.service.getObject(this.government_id, this.id).subscribe((data: any) => {
      if (!data.object) {
        this.mainService.openRoute(this.router.url.split("/")[1] + '/create');
        return;
      }
      this.object = data.object;
      this.mainService.languages.forEach(language => {
        this.objectForm.controller['name_' + language.id].setValue(this.object.translations[language.id] ? this.object.translations[language.id].name : "");
      });
      this.objectForm.controller.active.setValue(this.object.active);
    });
  }
  ngOnInit() {

    var validationObject = {
      // name: ['', [Validators.required]],
      active: [1],
    };
    this.mainService.languages.forEach(element => {
      validationObject['name_' + element.id] = ['', [Validators.required]];
    });
    this.objectForm.formGroup = this.formBuilder.group(validationObject);
    this.objectForm.submitAction = function (parent) {
      console.log(this.formGroup.value);
      this.submit = true;
      this.loader = true;
      if (!this.formGroup.valid) {
        this.loader = false;
        return;
      }
      parent.service.createUpdate(parent.government_id, this.formGroup.value, parent.id).subscribe((data: any) => {
        this.loader = false;
        this.submit = false;
        if (data.status == 1) {
          parent.mainService.show_notifications("Successfully " + (parent.id ? 'Edit' : 'Create'));
          if (!parent.id) {
            parent.mainService.openRoute(
              parent.router.url.split("/")[1] + "/" +
              parent.router.url.split("/")[2] + "/" +
              parent.router.url.split("/")[3] + "/" +
              parent.router.url.split("/")[4] + "/" +
               'edit/' + data.id);
          }
        }
        else {
          parent.mainService.showFormErrors(data.errors);
        }
      });
    };
  }

}
