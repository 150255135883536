import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from 'src/app/core/services/main.service';
import { formObject } from 'src/app/pages/helper/formObject';
import { ActivatedRoute, Router } from '@angular/router';

import { ServiceService } from '../service.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  objectForm = new formObject;
  id = null;
  object = null;
  imageUrl = "";
  gender_types = ['male', 'female'];
  DriverLicenseTypesList = [];
  AgenciesList = [];
  PackagesList = [];
  GovernmentsWithCitiesList = [];
  CitiesList = [];
  AreasList = [];
  VehiclesList = [];
  default_get = null;
  ShoolsList = [];

  constructor(
    private router: Router,
    private mainService: MainService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private service: ServiceService,

  ) {
    this.getGovernmentsWithCities();
    this.getDriverLicenseTypes();
    this.getAgencies();
    this.getPackages();
    this.getSchoolz();
    this.getVehicles();
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.mainService.setTitle("Drivers " + (this.id ? "Edit" : "Create"));
      this.mainService.changeBreadCrumbItems([
        { label: 'Drivers', path: '/users/drivers', active: false },
        { label: (this.id ? "Edit" : "Create"), path: "", active: true },
      ]);

    });

    this.activatedRoute.queryParams
      .subscribe(params => {
        this.default_get = params;
        if (this.id)
          this.getObject(this.id);
        else {
          if (this.default_get && this.default_get.image_license) {
            this.mainService.loadDropify(this.default_get.imageUrl + this.default_get.image_license, "image_license");
          }
          // this.mainService.loadDropify();
          // } else {
          this.mainService.loadDropify();
          // }

        }
        console.log("yes");
        console.log("yes");
      }
      );
  }

  getVehicles() {
    this.service.getVehicles().subscribe((data: any) => {
      this.VehiclesList = data;
    });
  }

  getObject(id) {
    this.service.getObject(id).subscribe((data: any) => {
      if (!data.object) {
        this.mainService.openRoute(this.router.url.split("/")[1] + '/create');
        return;
      }
      this.object = data.object;
      this.imageUrl = data.imageUrl;
      this.objectForm.controller.vehicle_id.setValue(this.object.vehicle_id);
      this.objectForm.controller.name.setValue(this.object.name);
      this.objectForm.controller.gender.setValue(this.object.gender);
      this.objectForm.controller.phone.setValue(this.object.phone);
      this.objectForm.controller.email.setValue(this.object.email);
      this.objectForm.controller.address.setValue(this.object.address);
      this.objectForm.controller.date_of_birth.setValue(this.mainService.toDateNgbDatepicker(this.object.date_of_birth));
      this.objectForm.controller.license_issue_date.setValue(this.mainService.toDateNgbDatepicker(this.object.license_issue_date));
      this.objectForm.controller.license_expiry_date.setValue(this.mainService.toDateNgbDatepicker(this.object.license_expiry_date));
      // console.log(this.object.date_of_birth);

      this.objectForm.controller.license_details.setValue(this.object.license_details);
      this.objectForm.controller.license_number.setValue(this.object.license_number);
      this.objectForm.controller.experience_yeears.setValue(this.object.experience_yeears);
      this.objectForm.controller.active.setValue(this.object.active);
      this.objectForm.controller.government_id.setValue((this.object.area && this.object.area.city) ? this.object.area.city.government_id : null);
      this.objectForm.controller.agency_id.setValue(this.object.agency_id);
      this.objectForm.controller.package_id.setValue(this.object.package_id);
      this.objectForm.controller.package_cost.setValue(this.object.package_cost);
      this.objectForm.controller.driver_license_type_id.setValue(this.object.driver_license_type_id);
      this.objectForm.controller.school_id.setValue(this.object.school_id);

      this.mainService.loadDropify(this.object.image ? this.imageUrl + this.object.image : "", "image");
      this.mainService.loadDropify(this.object.image_license ? this.imageUrl + this.object.image_license : "", "image_license");
      this.mainService.loadDropify(this.object.image_drug_analysis ? this.imageUrl + this.object.image_drug_analysis : "", "image_drug_analysis");
      this.mainService.loadDropify(this.object.image_criminal_fisheye ? this.imageUrl + this.object.image_criminal_fisheye : "", "image_criminal_fisheye");
      this.updateCityWhenEditAfterLoadObject();

    });
  }

  getSchoolz() {
    this.service.getSchoolz().subscribe((data: any) => {
      this.ShoolsList = data;
    });
  }

  getGovernmentsWithCities(reset = false) {
    if (reset == true) {
      this.objectForm.controller.government_id.setValue(null);
      this.objectForm.controller.city_id.setValue(null);
      this.objectForm.controller.area_id.setValue(null);
    }
    this.mainService.getGovernmentsWithCities().subscribe((data: any) => {
      this.GovernmentsWithCitiesList = data;
      this.updateCityWhenEditAfterLoadObject();
    });
  }

  getDriverLicenseTypes() {
    this.service.getDriverLicenseTypes().subscribe((data: any) => {
      this.DriverLicenseTypesList = data;
      // this.objectForm.controller.driver_license_type_id.setValue(this.object.driver_license_type_id);

    });
  }

  getAgencies() {
    this.service.getAgencies().subscribe((data: any) => {
      this.AgenciesList = data;
    });
  }

  getPackages() {
    this.service.getPackages().subscribe((data: any) => {
      this.PackagesList = data;
    });
  }


  updateCityWhenEditAfterLoadObject() {
    if (this.GovernmentsWithCitiesList.length > 0 && this.object && this.object.area && this.object.area.city && this.object.area.city.government_id) {
      this.GovernmentsWithCitiesList.forEach(government => {
        if (government.id == this.object.area.city.government_id) {
          this.CitiesList = government.cities;
          this.objectForm.controller.city_id.setValue(this.object.area.city_id);
          this.updateAreaWhenEditAfterLoadObject();
        }
      });
    }
  }
  updateAreaWhenEditAfterLoadObject() {
    if (this.CitiesList.length > 0 && this.object && this.object.area && this.object.area.city) {
      this.CitiesList.forEach(city => {
        if (city.id == this.object.area.city_id) {
          this.AreasList = city.areas;
          this.objectForm.controller.area_id.setValue(this.object.area_id);
        }
      });
    }
  }

  ngOnInit() {

    this.objectForm.formGroup = this.formBuilder.group({
      name: [this.default_get ? this.default_get.name : '', [Validators.required]],
      gender: [this.default_get ? this.default_get.gender : null, [Validators.required]],
      government_id: [null, [Validators.required]],
      city_id: [null, [Validators.required]],
      area_id: [null, Validators.required],
      address: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: [this.default_get ? this.default_get.phone : '', [Validators.required]],
      password: ['', this.id ? [] : [Validators.required]],
      date_of_birth: ['', [Validators.required]],
      license_details: ['', [Validators.required]],
      license_number: ['', [Validators.required]],
      driver_license_type_id: [null, [Validators.required]],
      agency_id: [null, [Validators.required]],
      package_id: [null, [Validators.required]],
      package_cost: [null, [Validators.required]],
      license_issue_date: ['', [Validators.required]],
      license_expiry_date: ['', [Validators.required]],
      experience_yeears: ['', [Validators.required]],
      school_id: [null],
      image: [''],
      vehicle_id: [],
      image_license: [''],
      image_license_copy: [this.default_get ? this.default_get.image_license : ''],
      image_drug_analysis: [''],
      image_criminal_fisheye: [''],
      active: [1],
    });




    this.objectForm.submitAction = function (parent) {
      this.formGroup.value.date_of_birth = this.formGroup.value.date_of_birth.year + '-' + this.formGroup.value.date_of_birth.month + '-' + this.formGroup.value.date_of_birth.day;
      this.formGroup.value.license_issue_date = this.formGroup.value.license_issue_date.year + '-' + this.formGroup.value.license_issue_date.month + '-' + this.formGroup.value.license_issue_date.day;
      this.formGroup.value.license_expiry_date = this.formGroup.value.license_expiry_date.year + '-' + this.formGroup.value.license_expiry_date.month + '-' + this.formGroup.value.license_expiry_date.day;
      // console.log(this.formGroup.value.license_issue_date);
      // return;
      this.submit = true;
      this.loader = true;
      if (!this.formGroup.valid) {
        this.loader = false;
        return;
      }
      parent.service.createUpdate(this.formGroup.value, parent.id).subscribe((data: any) => {
        this.loader = false;
        this.submit = false;
        if (data.status == 1) {
          parent.mainService.show_notifications("Successfully " + (parent.id ? 'Edit' : 'Create'));
          if (!parent.id) {
            parent.mainService.openRoute(parent.router.url.split("/")[1] + "/" + parent.router.url.split("/")[2] + '/edit/' + data.id);
          }
        }
        else {
          parent.mainService.showFormErrors(data.errors);
        }
      });
    };
  }

  changeGovernment(goverment) {
    this.CitiesList = goverment ? goverment.cities : [];
    this.objectForm.controller.city_id.setValue(null);
    this.objectForm.controller.area_id.setValue(null);
    this.AreasList = [];
  }

  changePackageCost(object) {
    if (object) {
      this.objectForm.controller.package_cost.setValue(object.cost);
    }
    else
      this.objectForm.controller.package_cost.setValue(null);
  }


  changeCity(city) {
    this.AreasList = city ? city.areas : [];
    this.objectForm.controller.area_id.setValue(null);
  }

  imageChanged(function_name, files: FileList) {
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      console.log(function_name);
      reader.onload = this[function_name].bind(this);
      reader.readAsBinaryString(file);
    }
  }
  _handleReaderLoadedImageProfile(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.objectForm.controller.image.setValue(btoa(binaryString));
  }

  _handleReaderLoadedImageLicense(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.objectForm.controller.image_license.setValue(btoa(binaryString));
  }

  _handleReaderLoadedImageDrugAnalysis(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.objectForm.controller.image_drug_analysis.setValue(btoa(binaryString));
  }

  _handleReaderLoadedImageCriminalFisheye(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.objectForm.controller.image_criminal_fisheye.setValue(btoa(binaryString));
  }


}
