import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CookieService } from '../services/cookie.service';
import { User } from '../models/auth.models';
import { Router } from '@angular/router';
import { MainService } from './main.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    user = null;
    quard = null;
    lang = "en";

    constructor(private http: HttpClient,
        private cookieService: CookieService,
        public mainService: MainService,
        private router: Router,
    ) {
        this.updateProfile();
    }


    /**
     * Returns the current user
     */
    public currentUser(): User {
        // console.log(document.cookie);
        if (!this.user) {
            if (this.cookieService.getCookie('currentUser') && this.cookieService.getCookie('currentQuard')){
                this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
                this.quard = JSON.parse(this.cookieService.getCookie('currentQuard'));
                this.updateProfile();
            }

        }
        return this.user;
    }

    public updateProfile() {
        if (this.user) {
            if (this.quard == "user") {
                this.http.get(this.mainService.apiUrl + "user/getUserData", {}).subscribe((data: any) => {
                    let user = data.object;
                    user.token = this.user.token;
                    this.setUser(user, this.quard);
                });
            } else if (this.quard == "school") {
                this.http.get(this.mainService.apiUrl + "school/getUserData", {}).subscribe((data: any) => {
                    let user = data.object;
                    user.token = this.user.token;
                    this.setUser(user, this.quard);
                });
            }

        }
    }
    /**
     * Set the current user
     */
    public setUser(user, quard = "users") {
        this.user = user;
        this.quard = quard;
        this.cookieService.setCookie('currentUser', JSON.stringify(user), 10);
        this.cookieService.setCookie('currentQuard', JSON.stringify(quard), 10);
        // console.log(this.currentUser());
        // console.log(JSON.parse(this.cookieService.getCookie('currentUser')));
        // console.log('monem');

    }
    public postuserLogin(object) {
        let url = "user/auth/login";
        return this.http.post(this.mainService.apiUrl + url, object);
    }

    public postschoolLogin(object) {
        let url = "school/auth/login";
        return this.http.post(this.mainService.apiUrl + url, object);
    }

    deleteCoolkieNavigateLogin() {

        this.cookieService.deleteCookie('currentUser');
        this.user = null;
        var quard = this.quard;
        this.cookieService.deleteCookie('currentQuard');
        this.quard = null;

        this.router.navigate(['/account/' + quard + '/login']);
    }

    auth403() {
        this.updateProfile();
        this.router.navigate(['/' + this.quard + '/dashboards']);
    }


    /**
     * Logout the user
     */
    logout() {
        this.http.post(this.mainService.apiUrl + "user/auth/logout", {}).subscribe((data: any) => {
            if (data.status == 1) {
                // remove user from local storage to log user out
                this.deleteCoolkieNavigateLogin();
            }
            else {
                alert("Server Error Call Support");
            }
        });
    }
}

